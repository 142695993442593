import { Component, OnInit, Input, OnDestroy, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject, merge } from "rxjs";
import {
  takeUntil,
  switchMap,
  startWith,
  debounceTime,
  mergeMap,
} from "rxjs/operators";
import { AuthService } from "src/app/core/services/auth.service";
import { ProductService } from "../services/product.service";
import { DisplayGroupService, GeneralService } from "../services";
import { IDisplayGroups } from "src/app/core/ITypes";
import {
  SeoService,
  SiteConfigService,
  AnalyticsService,
} from "src/app/core/services";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { CommandEmitterService } from "../services/command-emitter.service";
import { Command } from "../services/command";
import { CollicoService } from "../services/collico.service";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "sv-product-grid",
  templateUrl: "./product-grid.component.html",
  styleUrls: ["./product-grid.component.scss"],
})
export class ProductGridComponent implements OnInit, OnDestroy {
  // defaults
  @Input() featured?: Boolean;
  @Input() searchPage?: Boolean;
  @Input() detailProductId?: string;
  @Input() displayGroup?: string;
  @Input() columns: number = 4;

  private unsubscribe$ = new Subject<void>();
  loggedIn: boolean = false;
  loading: boolean = true;
  products = [];
  groupedProducts: { vendor: string; products: any[] }[] = [];
  limit: string = "21";
  page: string = "1";
  selectedDisplayGroup: IDisplayGroups = null;
  updateProductsInProgress = false;
  noProducts: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private displayGroupService: DisplayGroupService,
    private router: Router,
    public generalService: GeneralService,
    public seoService: SeoService,
    public translateService: TranslateService,
    public siteConfigService: SiteConfigService,
    private analyticsService: AnalyticsService,
    private commandEmitterService: CommandEmitterService,
    private collicoService: CollicoService,
    private viewportScroller: ViewportScroller
  ) {
    this.registerKeyboardMouseUsage();
  }

  // enables switching between KEYBOARD and MOUSE movement
  // detects it and then passes it on to child product components
  registerKeyboardMouseUsage() {
    const document = this.generalService.getDocument;
    document.addEventListener("click", (event) => {
      const command = this.commandEmitterService.createCommand(
        Command.IS_USING_KEYBOARD,
        false
      );
      this.commandEmitterService.emitCommand(command);
    });
    document.addEventListener("keydown", (event) => {
      if (event.key === "Tab") {
        const command = this.commandEmitterService.createCommand(
          Command.IS_USING_KEYBOARD,
          true
        );
        this.commandEmitterService.emitCommand(command);
      }
    });
  }

  async ngOnInit() {
    // Combine all observables to trigger the update when either of them emits
    merge(
      this.route.queryParams,
      this.collicoService.collicoDeliveryDateAndTimeChanged$,
      this.collicoService.collicoZipChanged$,
      this.productService.refetchProductListing$
    )
      .pipe(
        debounceTime(300),
        takeUntil(this.unsubscribe$),
        mergeMap(async () => {
          // Check if updateProducts is already in progress
          if (!this.updateProductsInProgress) {
            // Set the flag to indicate that updateProducts is in progress
            this.updateProductsInProgress = true;

            // Execute updateProducts and wait for it to complete
            await this.updateProducts();

            // Reset the flag after updateProducts is completed
            this.updateProductsInProgress = false;
          }
        })
      )
      .subscribe();

    // observe user loggedIn
    this.authService.isLoggedIn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
      });

    // observe products loading
    this.productService
      .observeLoading()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loading) => {
        this.loading = loading;
      });

    // get products
    if (this.featured === true) {
      this.products = await this.getFeaturedProducts(true);
      this.groupedProducts = this.groupProducts(this.products);
    }

    let { pathname } = this.document.location;
    this.analyticsService.productListEvent(pathname, this.products);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onScrollDown() {
    let { related_products } = this.route.snapshot.queryParams;

    if (!related_products) {
      this.page = String(Number(this.page) + 1);
      // sending requests here for more products
      const products = await this.getProducts(false);
      this.products = this.products.concat(...products);
      this.groupedProducts = this.groupProducts(products);
    }
  }

  private async getFeaturedProducts(loading: boolean) {
    return await this.productService.getFeaturedProducts({}, loading);
  }

  private async getProducts(loading: boolean) {
    let {
      group,
      q,
      tag,
      subGroup,
      filters,
      related_products,
      only_vendor_products,
    } = this.route.snapshot.queryParams;
    let filtersObj = {};

    if (subGroup) {
      this.getPageInfo(subGroup, true);
    } else if (group) {
      this.getPageInfo(group);
    } else {
      this.selectedDisplayGroup = null;
    }

    if (filters) {
      filters = JSON.parse(filters);
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i];
        filtersObj = { ...filtersObj, [f]: true };
      }
    }

    if (related_products && related_products.length) {
      this.products = [];
      this.groupedProducts = [];

      return await this.productService.getProducts(
        {
          limit: this.limit,
          page: this.page,
          search: related_products,
        },
        loading,
        "/related"
      );
    }

    if (tag) {
      return await this.productService.getProducts(
        {
          limit: this.limit,
          page: this.page,
          key: tag,
          ...filtersObj,
        },
        loading
      );
    } else {
      return await this.productService.getProducts(
        {
          limit: this.limit,
          page: this.page,
          display_group_code: subGroup || group || this.displayGroup || "",
          search: q || "",
          ...filtersObj,
          ...(only_vendor_products && { only_vendor_products }),
        },
        loading
      );
    }
  }

  private async getPageInfo(groupCode?: string, isSubGroup?: boolean) {
    const groups: any = this.displayGroupService.displayGroups;

    if (groups) {
      if (isSubGroup) {
        for (let i = 0; i < groups.length; i++) {
          const g = groups[i];
          for (let j = 0; j < g.display_groups.length; j++) {
            const d = g.display_groups[j];
            if (d.code === groupCode) {
              this.selectedDisplayGroup = d;
            }
          }
        }
      } else if (!isSubGroup) {
        let found = groups.find((group) => group.code === groupCode);
        this.selectedDisplayGroup = found;
      }

      //............. setting meta stuff starts .............
      const tenant = this.siteConfigService.siteConfig.tenant;
      let metaObj = require(`src/app/tenants/${tenant}/seo/meta.json`);

      let page = metaObj.pages.find((p) => {
        return p.key === groupCode;
      });

      if (page) {
        this.seoService.setTitle = page.title;
        this.seoService.setMetaTags = [
          { name: "description", content: page.description },
          { name: "keywords", content: page.title },
          { name: "robots", content: "index, follow" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { charset: "UTF-8" },
        ];
      }
      //............. setting meta stuff ends .............
    }
  }

  private groupProducts(products) {
    const groupedProducts = this.groupedProducts;
    for (const product of products) {
      // Check if there's a group for the vendor, and if not, create it
      const groupIndex = groupedProducts.findIndex(
        (group) => group.vendor === product.vendor
      );
      if (groupIndex === -1) {
        groupedProducts.push({ vendor: product.vendor, products: [] });
      }

      // Add the product to the corresponding group
      groupedProducts
        .find((group) => group.vendor === product.vendor)
        .products.push(product);
    }

    return groupedProducts;
  }

  private scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  private async updateProducts() {
    if (this.searchPage) {
      this.noProducts = false;

      this.productService
        .observeLoading()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((loading) => {
          this.loading = loading;
        });

      // Clear previous listings
      this.groupedProducts = [];
      this.products = [];
      this.page = "1";

      // Fetch new products
      const newProducts = await this.getProducts(true);

      // Assign the new products
      this.products = newProducts;

      // Group the products
      this.groupedProducts = this.groupProducts(this.products);

      // only one product, then navigate to product url
      if (this.products && this.products.length === 1) {
        this.router.navigate(
          this.products[0].F001
            ? [`/p/${this.products[0].F001}/${this.products[0].C001}`]
            : [`/p/${this.products[0].F002}/${this.products[0].C001}`],
          { queryParams: { group: this.products[0].D001 } }
        );
      } else if (this.products.length === 0) {
        this.noProducts = true;
      }
    }
  }
}
