<div class="c-headerNavbarContainerX" #svNavbarX id="navBarX">
  <div class="l-page">
    <div class="c-headerNavbar">
      <div class="c-headerNavbar-navPanel">
        <button class="b-headerNavbar js-toggle-navPanel" (click)="handleToggleProductTree()">
          <span class="is-icon icon-menu"></span>
        </button>
      </div>

      <div class="c-headerNavbar-brandContainer">
        <a class="c-headerNavbar-brand store-logo" title="{{ logo.label }}" routerLink="/">
          <img src="{{ logo.src }}" class="c-headerNavbar-brand-image" alt="{{ logo.label }}" />
        </a>
      </div>

      <div class="c-headerNavbar-menuContainer">
        <ul class="c-headerNavbar-menu" *ngIf="isMenuItemsVisible">
          <li *ngFor="let menuPage of menuPages" class="c-headerNavbar-menu-item">
            <a *ngIf="menuPage.externalLink" [href]="menuPage.externalLink" class="c-headerNavbar-menu-link">
              {{ menuPage.title }}
            </a>

            <a *ngIf="!menuPage.externalLink" [routerLink]="menuPage.link" class="c-headerNavbar-menu-link">
              {{ menuPage.title }}
            </a>
          </li>
          <li class="c-headerNavbar-menu-item" *ngIf="isRecipeVisible && isRecipeVisible === true">
            <a routerLink="/recipes" class="c-headerNavbar-menu-link">
              <span *ngIf="generalService.translationMode === true">
                (N009)
              </span>
              {{ "NAVBAR.N009.value" | translate }}
            </a>
          </li>
        </ul>

        <ul class="c-headerNavbar-menu" *ngIf="staticHeaderPages.length > 0">
          <li *ngFor="let headerPage of staticHeaderPages" class="c-headerNavbar-menu-item">
            <a *ngIf="headerPage.slug" [routerLink]="'/page/' + headerPage.slug" class="c-headerNavbar-menu-link">
              {{ headerPage.title }}
            </a>
          </li>

          <li class="c-headerNavbar-menu-item" *ngIf="isRecipeVisible && isRecipeVisible === true">
            <a routerLink="/recipes" class="c-headerNavbar-menu-link">
              <span *ngIf="generalService.translationMode === true">
                (N009)
              </span>
              {{ "NAVBAR.N009.value" | translate | titlecase }}
            </a>
          </li>
        </ul>
        <button class="c-headerNavbar-menuToggle b-headerNavbar js-toggle-headerMenu"
          (click)="togglePageMobileDropdown()">
          <span class="is-icon icon-dots-to-close-2"></span>
        </button>
      </div>

      <div class="c-headerNavbar-tools user-ignore">
        <!-- --------------------  Not Loggedin Mobile view starts ------------------ -->

        <ng-container *ngIf="!loggedIn">
          <button class="b-headerNavbar b-headerNavbar--toggle-headerTools h-show--mobile" (click)="toggleSearchBar()"
            [ngClass]="{
              'is-visible': generalService.scrollHeight > 0
            }">
            <span class="is-icon icon-search"></span>
          </button>

          <button class="b-headerNavbar js-dropdown-toggle h-show--mobile">
            <span class="is-icon icon-delivery-truck" (click)="toggleZip()"></span>
          </button>

          <button class="b-headerNavbar b--hasBadge h-show--mobile js-toggle-cartPanel"
            (click)="handleToggleCartPanel()">
            <span class="is-icon icon-buy"> </span>
            <span class="c-badge" *ngIf="cart.products.length">
              {{ cart.products.length }}

              <!-- 21 -->
            </span>
          </button>
        </ng-container>

        <div *ngIf="!loggedIn" class="c-dropdown h-show--mobile user-ignore" [ngClass]="{
            'c-dropdown--isOpen': userDropdown === true
          }">
          <button class="b-headerNavbar user-ignore" (click)="userDropdownToggle()">
            <span class="is-icon icon-user user-ignore"></span>
          </button>

          <div class="c-dropdown__options user-ignore" *ngIf="userDropdown === true">
            <div class="c-dropdown__items user-ignore">
              <div class="c-dropdown__item user-ignore">
                <button class="c-dropdown__link user-ignore" (click)="signInModal()">
                  <span *ngIf="generalService.translationMode === true">
                    (G002)
                  </span>
                  {{ "GLOBAL.G002.value" | translate }}
                </button>
              </div>
              <div class="c-dropdown__item user-ignore" *ngIf="!loggedIn">
                <button class="c-dropdown__link user-ignore" (click)="signUpModal()">
                  <span *ngIf="generalService.translationMode === true">
                    (G003)
                  </span>
                  {{ "GLOBAL.G003.value" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------  Not Loggedin Mobile view ends   ------------------ -->

        <!-- --------------------  Not Loggedin Desktop view starts ------------------ -->
        <ng-container *ngIf="!loggedIn">
          <button class="b-headerNavbar h-hide--mobile" data-target="#sv-auth-modal" (click)="signInModal()">
            <span *ngIf="generalService.translationMode === true">
              (G002)
            </span>
            {{ "GLOBAL.G002.value" | translate }}
          </button>
          <button class="b-headerNavbar h-hide--mobile" (click)="signUpModal()">
            <span *ngIf="generalService.translationMode === true">
              (G003)
            </span>
            {{ "GLOBAL.G003.value" | translate }}
          </button>
        </ng-container>
        <!-- --------------------  Not Loggedin Desktop view ends   ------------------ -->

        <!-- --------------------  Loggedin view starts ------------------ -->
        <div *ngIf="loggedIn" class="c-dropdown user-ignore">
          <button class="b-headerNavbar b-headerNavbar--toggle-headerTools h-show--mobile loggedinBtns"
            (click)="toggleSearchBar()" [ngClass]="{
              'is-visible': generalService.scrollHeight > 0
            }">
            <span class="is-icon icon-search"></span>
          </button>

          <button class="b-headerNavbar js-dropdown-toggle loggedinBtns">
            <span class="is-icon icon-delivery-truck" (click)="toggleZip()"></span>
          </button>

          <button class="b-headerNavbar b--hasBadge h-show--mobile js-toggle-cartPanel loggedinBtns"
            (click)="handleToggleCartPanel()">
            <span class="is-icon icon-buy"> </span>
            <span class="c-badge" *ngIf="cart.products.length">
              {{ cart.products.length }}
            </span>
          </button>

          <button class="b-headerNavbar js-dropdown-toggle c-dropdown__toggleButton user-ignore"
            (click)="userDropdownToggle()">
            <span class="is-icon icon-user user-ignore"></span>
            <span class="h-hide--mobile user-ignore">
              {{ (user && user.displayName) }}
            </span>
          </button>
          <div class="c-dropdown__options user-ignore" *ngIf="userDropdown === true">
            <div class="c-dropdown__items user-ignore">
              <div class="c-dropdown__item user-ignore" *ngIf="isDashboardVisible === true">
                <a *ngIf="isAccountVisible === true" routerLink="/dashboard" class="c-dropdown__link">
                  <span *ngIf="generalService.translationMode === true">
                    (A013)
                  </span>
                  {{ "AUTH.A013.value" | translate }}
                  <!-- Account info -->
                </a>
                <a *ngIf="isAddressesVisible === true" routerLink="/dashboard/delivery" class="c-dropdown__link">
                  <!-- Delivery addresses -->

                  <span *ngIf="generalService.translationMode === true">
                    (C050)
                  </span>
                  {{ "CART.C050.value" | translate }}
                </a>
                <a *ngIf="isOrdersVisible === true" routerLink="/dashboard/orders" class="c-dropdown__link">
                  <!-- Order history -->

                  <span *ngIf="generalService.translationMode === true">
                    (A014)
                  </span>
                  {{ "AUTH.A014.value" | translate }}
                </a>
                <a *ngIf="isRefundsVisible === true" routerLink="/dashboard/refunds" class="c-dropdown__link">
                  <!-- Balance refunds -->
                  <span *ngIf="generalService.translationMode === true">
                    (A020)
                  </span>
                  {{ "AUTH.A020.value" | translate }}
                </a>
              </div>
              <div class="c-dropdown__item user-ignore">
                <button class="c-dropdown__link user-ignore" (click)="signOutModal()">
                  <!-- Sign out -->
                  <span *ngIf="generalService.translationMode === true">
                    (G021)
                  </span>
                  {{ "GLOBAL.G021.value" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------  Loggedin view ends   ------------------ -->
      </div>
    </div>
  </div>
</div>