<div #inputForm [ngClass]="{
  'c-productShortList__item-addToCart': true,
  'c-addToCart--isRelative': parent === 'product-detail' ? true : false,
  'c-addToCart--minified': parent === 'cart-panel' ? true : false,
  'c-productsList__item-addToCart':
    parent !== 'cart-panel' && parent !== 'checkout-page' ? true : false,
  'c-addToCart--input': parent === 'checkout-page' ? true : false,
  'c-addToCart--isVisible': isCounterVisible ? true : false,
  'c-addToCart': true,
  'c-addToCart--askRemove': isRemoveBtnVisible ? true : false
}" [ngStyle]="{ 'margin-left': parent === 'product-detail' ? '4rem' : '0' }">
  <!-- cart button -->
  <!-- FORM NOT OPEN, [+] -->
  <!-- cart button NO SELECTED ITEM / QUANTITY === 0 -->
  <!-- notPurchaseable === true  -->
  <!-- cart button if disabled -->
  <!-- FORM NOT OPEN, ITEM UNAVAILABLE -->
  <ng-container *ngIf="data?.isPurchasable?.product_mode === PRODUCT_MODE_UNAVAILABLE; else elseBuyButton">
    <button data-placement="bottom"
      class="b-addToCart b-addToCart--toggle b-addToCart--noDelivery js-toggle-addToCart disabled-buyButton" tooltip
      #noDeliveryTextHint="tooltip" (mouseenter)="switchToolTip(data.isPurchasable.error)">
      <span class="visuallyhidden">Item {{ data.F001 }} is unavailable</span>
      <span class="c-icon icon-prohibit" aria-hidden="true"></span>
    </button>
  </ng-container>

  <!-- cart button if enabled -->
  <!-- FORM NOT OPEN, ITEM IS AVAILABLE-->
  <!-- DISPLAYS ITEM QUANTITY OR [+] ICON -->
  <ng-template #elseBuyButton>

    <!-- Buy Button Yellow, Change Date feature -->
    <ng-container
      *ngIf="data?.isPurchasable?.product_mode === PRODUCT_MODE_CAN_BE_RESUPPLIED; else elseBuyButtonNormal">
      <button data-placement="bottom"
        class="b-addToCart b-addToCart--toggle b-addToCart--futureDelivery disabled-buyButton" tooltip
        #noDeliveryTextHint="tooltip" (mouseenter)="switchToolTip(data.isPurchasable.error)"
        (mousedown)="handleOpenCounterFutureDeliveryClick($event)"
        (keydown)="handleOpenCounterFutureDeliveryClick($event)">
        <!-- <span class="c-icon icon-prohibit" aria-hidden="true"></span> -->
        <span [ngClass]="['is-icon', buyIcon]"> </span>
      </button>
    </ng-container>

    <!-- Buy Button Normal, No Change Date feature -->
    <ng-template #elseBuyButtonNormal>
      <button id="show_counter" class="b-addToCart b-addToCart--toggle" (mousedown)="handleOpenCounterClick($event)"
        (keydown)="handleOpenCounterClick($event)" [ngClass]="{
        'b-addToCart--toggle-inCart': counter ? true : false
      }" [disabled]="isAddingToCart">
        <span [ngClass]="['is-icon', buyIcon]" [hidden]="counter"> </span>
        <div [hidden]="!counter">{{ counter }}</div>
        <span class="visuallyhidden">Add {{ data.F001 }} to cart</span>
      </button>

      <!-- remove item button -->
      <button (click)="handleRemoveProduct(data)" class="b-addToCart b-addToCart--remove">
        <span class="icon-trash" aria-hidden="true"></span>
        <span class="visuallyhidden">Add to cart</span>
      </button>
    </ng-template>
  </ng-template>

  <div class="c-addToCart__forms" [ngClass]="editCartQtyClass" (mouseleave)="handleStartDelay()"
    (mouseover)="handleClearDelay()">
    <!-- decrement -->
    <!-- FORM OPEN, [-] -->
    <button #decreaseInput [tabindex]="-1" svThrottleClick (throttleClick)="handleDec()" [throttleTime]="250"
      class="b-addToCart b-addToCart--down" (blur)="handleHideCounter()" [disabled]="isAddingToCart"
      (keydown.shift.tab)="keyboardPress($event, '-')" (keydown.tab)="keyboardPress($event, '-')">
      <span [ngClass]="{
        'icon-down': parent && parent === 'cart-panel',
        'icon-remove': !parent || parent !== 'cart-panel'
      }" aria-hidden="true"></span>
      <span class="visuallyhidden">Decrease {{ data.F001 }} by 1</span>
    </button>

    <!-- quantity input -->
    <!-- FORM OPEN, NUMBER INPUT [23] -->
    <input #quantityInput [tabindex]="-1" type="number" class="c-addToCart__input" [(ngModel)]="counter" min="1"
      max="10" [placeholder]="0" [attr.aria-label]="data.F001 + ': ' + counter + ' items in cart'"
      (change)="counterChange($event)" (keydown.shift.tab)="keyboardPress($event, 'quantity')"
      (keydown.tab)="keyboardPress($event, 'quantity')" />

    <button (click)="handleRemoveProduct(data)" class="b-addToCart b-addToCart--remove">
      <span class="icon-trash" aria-hidden="true"></span>
      <span class="visuallyhidden">Add to cart</span>
    </button>

    <!-- increment -->
    <!-- FORM OPEN, [+] -->
    <button #increaseInput class="b-addToCart b-addToCart--up disabled" tooltip #noDeliveryTextHint="tooltip"
      (mouseenter)="switchToolTip('CART.C063.value')" *ngIf="
      isNeedsAvailabilityCheck && counter >= availability;
      else elseIncreaseInputButton
    ">
      <span class="icon-up" [ngClass]="{
        'icon-up': parent && parent === 'cart-panel',
        'icon-add': !parent || parent !== 'cart-panel'
      }" aria-hidden="true"></span>
      <span class="visuallyhidden">Increase {{ data.F001 }} by 1</span>
    </button>

    <ng-template #elseIncreaseInputButton>
      <button #increaseInput [tabindex]="0" svThrottleClick (throttleClick)="handleInc()" [throttleTime]="250"
        class="b-addToCart b-addToCart--up" (keydown.shift.tab)="keyboardPress($event, '+')"
        (keydown.tab)="keyboardPress($event, '+')" [disabled]="isAddingToCart">
        <span class="icon-up" [ngClass]="{
          'icon-up': parent && parent === 'cart-panel',
          'icon-add': !parent || parent !== 'cart-panel'
        }" aria-hidden="true"></span>
        <span class="visuallyhidden">Increase {{ data.F001 }} by 1</span>
      </button>
    </ng-template>
  </div>

  <span *ngIf="generalService.debugMode">
    <br />
    <small> [{{ data?.isPurchasable?.step }}] </small>
  </span>
</div>