import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { delay, isEmpty } from "lodash";

import {
  CartService,
  AnalyticsService,
  CacheService,
  SiteConfigService,
  CentralStorageService,
} from "src/app/core/services";
import { GeneralService, ProductService } from "../services";
import { IProduct } from "../product-card/IProduct";
import { CollicoService } from "../services/collico.service";
import { TranslateService } from "@ngx-translate/core";
import { CommandEmitterService } from "../services/command-emitter.service";
import { Command } from "../services/command";

/**
 * Represents Dynamic Buy Button
 * @export BuyBtnComponent
 * @class BuyBtnComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: "sv-buy-btn",
  templateUrl: "./buy-btn.component.html",
  styleUrls: ["./buy-btn.component.scss"],
})
export class BuyBtnComponent implements OnInit, OnDestroy {
  // defaults
  @Input() data: IProduct;
  @Input() parent: string;
  @Input() editCartQtyClass: string;

  @ViewChild("noDeliveryTextHint", { static: false }) noDeliveryTextHint;
  @ViewChild("inputForm") inputForm: ElementRef<HTMLDivElement>;
  @ViewChild("decreaseInput") decreaseInput: ElementRef;
  @ViewChild("quantityInput") quantityInput: ElementRef;
  @ViewChild("increaseInput") increaseInput: ElementRef;

  PRODUCT_MODE_AVAILABLE = "available";
  PRODUCT_MODE_UNAVAILABLE = "unavailable";
  PRODUCT_MODE_CAN_BE_RESUPPLIED = "can_be_resupplied";

  counter: number = 0;
  delayId: number = null;
  removeBtnDelayId: number = null;
  delayDuration: number = 3000;
  isCounterVisible: boolean = false;
  isRemoveBtnVisible: boolean = false;
  isAddingToCart: boolean = false;
  buyIcon: string = ""; //icon-add
  isNeedsAvailabilityCheck: boolean = false;
  availability: number = 0;
  nextAvailableDateObj: any = null;

  private unsubscribe$ = new Subject<void>();
  private usingKeyboard = true;

  /**
   * Creates an instance of BuyBtnComponent.
   * @param {CartService} cartService
   * @param {AnalyticsService} analyticsService
   * @memberof BuyBtnComponent
   */
  constructor(
    private cartService: CartService,
    private toastr: ToastrService,
    private analyticsService: AnalyticsService,
    public generalService: GeneralService,
    public translate: TranslateService,
    public siteConfigService: SiteConfigService,
    private productService: ProductService,
    public messageService: CommandEmitterService,
    public collicoService: CollicoService,
    public centralStorageService: CentralStorageService
  ) {
    // registers message handlers
    this.messageService.enableCommandReceivement((message) =>
      this.messageHandler(message)
    );
  }

  /**
   * Assign initial counter value
   * @name ngOnInit
   * @memberof BuyBtnComponent
   */
  async ngOnInit() {
    this.buyIcon = this.siteConfigService.siteConfig.theme.buyButtonIcon;
    this.counter = this.getProductCount();

    if (this.data.isPurchasable) {
      const {
        status = false,
        needs_availablity_check,
        availability,
        product_availability_date,
      } = this.data.isPurchasable;

      this.nextAvailableDateObj = product_availability_date;

      //Check if availability needs to be check or not
      if (status && needs_availablity_check) {
        this.isNeedsAvailabilityCheck = true;
        this.availability = availability;
      }
    }

    this.cartService.cartDataObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cartData) => {
        if (cartData) {
          const { products } = cartData;
          const cartProduct = products.find((cp) => cp.id === this.data.C000);
          if (!isEmpty(cartProduct)) {
            this.data.quantity = cartProduct.quantity;
            this.counter = cartProduct.quantity;
          } else {
            this.data.quantity = 0;
            this.counter = 0;
          }
        }
      });
  }

  /**
   * clear delay from queue
   * @name ngOnDestroy
   * @memberof BuyBtnComponent
   */
  ngOnDestroy() {
    this.handleClearDelay();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * represents add first product
   * makes counter visible & assign products quantity
   * tracks activity
   * @name handleShowCounter
   * @memberof BuyBtnComponent
   */
  async handleShowCounter() {
    const { F020, C001: id } = this.data;
    this.showCounter();

    //Update product availability
    if (this.isNeedsAvailabilityCheck) {
      const { needs_availablity_check, availability } =
        await this.productService.checkProductAvailability(id);
      this.isNeedsAvailabilityCheck = needs_availablity_check;
      this.availability = availability;
    }

    if (!this.counter) {
      if (F020 && F020 === true) {
        if (
          !this.collicoService.collicoZip &&
          !this.collicoService.collicoAvailable
        ) {
          this.generalService.setCartPanelState = false;
          this.generalService.setModalState = {
            id: "limitedAvailabilityModal",
            name: "limitedAvailabilityModal",
            isOpen: true,
          };
        } else if (!this.collicoService.collicoAvailable) {
          this.generalService.setCartPanelState = false;
          this.generalService.setModalState = {
            id: "limitedAvailabilityModal2",
            name: "limitedAvailabilityModal2",
            isOpen: true,
          };
        }
      }

      this.handleInc();
      this.isAddingToCart = true;
    }

    this.handleStartDelay();
  }

  /**
   * represents increment of product
   * tracks activity & clears delay
   * @name handleInc
   * @memberof BuyBtnComponent
   */
  async handleInc() {
    if (this.isAddingToCart === false) {
      const { C000, F004, F012, F013 } = this.data;
      const minQtyToOrder = parseInt(F012);
      const maxQtyToOrder = parseInt(F013);
      let counter = Number(this.counter) + 1;

      if (!Number.isNaN(maxQtyToOrder) && counter > maxQtyToOrder) {
        this.toastr.warning(
          this.translate.instant("PRODUCT.P008.value", {
            maxQtyToOrder,
          })
        );
        this.counter = maxQtyToOrder;
        return false;
      }

      if (
        !Number.isNaN(minQtyToOrder) &&
        minQtyToOrder > 1 &&
        this.counter < minQtyToOrder
      ) {
        counter = minQtyToOrder;
      }

      this.counter = counter;
      try {
        await this.cartService.addToCart(this.data, this.counter);
        this.analyticsService.productEvent(
          "addToCart",
          "product_quantity_increased",
          C000,
          F004,
          this.counter,
          this.data
        );
      } catch (error) {
        //Check non stock availability
        if (error.error.data) {
          const { data: nonStockProducts } = error.error;
          this.highlightNonStockProducts(nonStockProducts);
        }
      } finally {
        this.isAddingToCart = false;
      }
    }
  }

  /**
   * represents decrement of product
   * tracks activity & clears delay
   * @name handleDec
   * @memberof BuyBtnComponent
   */
  async handleDec() {
    const { C000, F004, F012 } = this.data;
    const minQtyToOrder = parseInt(F012);
    let counter = Number(this.counter) - 1;

    if (counter >= 0) {
      if (
        !Number.isNaN(minQtyToOrder) &&
        minQtyToOrder > 1 &&
        counter < minQtyToOrder
      ) {
        counter = 0;
      }

      if (counter === 0) {
        if (this.parent === "cart-panel") {
          this.handleRemoveBtnStartDelay();
        } else {
          this.counter = counter;
          this.handleRemoveProduct(this.data);
        }
      } else {
        this.counter = counter;
        await this.cartService.addToCart(this.data, counter);
        this.analyticsService.productEvent(
          "removeFromCart",
          "product_quantity_decreased",
          C000,
          F004,
          counter,
          this.data
        );
      }
    }
  }

  async counterChange(e) {
    const { C000, F004, F012, F013 } = this.data;
    const minQtyToOrder = parseInt(F012);
    const maxQtyToOrder = parseInt(F013);
    const qty: number = Number(e.target.value);
    let type: string = "product_quantity_increased";

    if (!Number.isNaN(qty)) {
      if (qty > 0) {
        this.counter = qty;
        if (!Number.isNaN(maxQtyToOrder) && qty > maxQtyToOrder) {
          this.toastr.warning(
            this.translate.instant("PRODUCT.P008.value", {
              maxQtyToOrder,
            })
          );
          this.counter = maxQtyToOrder;
        }

        if (
          !Number.isNaN(minQtyToOrder) &&
          minQtyToOrder > 1 &&
          qty < minQtyToOrder
        ) {
          this.toastr.warning(
            this.translate.instant("PRODUCT.P009.value", {
              minQtyToOrder,
            })
          );
          this.counter = minQtyToOrder;
        }

        if (
          this.isNeedsAvailabilityCheck &&
          this.counter >= this.availability
        ) {
          const availability = this.availability;
          this.toastr.warning(
            this.translate.instant("PRODUCT.P008.value", {
              maxQtyToOrder: availability,
            })
          );
          this.counter = availability;
        }

        if (qty < this.counter) {
          type = "product_quantity_decreased";
        }

        try {
          await this.cartService.addToCart(this.data, this.counter);
          if (type === "product_quantity_increased") {
            this.analyticsService.productEvent(
              "addToCart",
              "product_quantity_increased",
              C000,
              F004,
              this.counter,
              this.data
            );
          } else {
            this.analyticsService.productEvent(
              "removeFromCart",
              "product_quantity_decreased",
              C000,
              F004,
              this.counter,
              this.data
            );
          }
        } catch (error) {
          //Check non stock availability
          if (error.error.data) {
            const { data: nonStockProducts } = error.error;
            this.highlightNonStockProducts(nonStockProducts);
          }
        }
      } else if (qty === 0) {
        this.handleRemoveProduct(this.data);
      }
    }
  }

  /**
   * remove product from cart
   * @name handleRemoveProduct
   * @param {string} id
   * @memberof BuyBtnComponent
   */
  async handleRemoveProduct(data: IProduct) {
    try {
      await this.cartService.removeProductFromCart(data.C000);
      this.handleLogRemoveProduct(data.C000, data.F004);
      this.handleRemoveBtnClearDelay();
    } catch (error) {
      this.counter = this.getProductCount();
      this.handleHideCounter();
      this.handleClearDelay();
      console.error("error in handleRemoveProduct: ", error);
    }
  }

  /**
   * hides the counter widget
   * @name handleHideCounter
   * @memberof BuyBtnComponent
   */
  handleHideCounter() {
    this.isCounterVisible = false;
  }

  showCounter() {
    this.isCounterVisible = true;
    this.closeOtherCounters();
  }

  closeOtherCounters() {
    const message = this.messageService.createCommand(
      Command.CLOSE_OTHER_COUNTERS,
      this.data.C000
    );
    this.messageService.emitCommand(message);
  }

  /**
   * adds the delay timeout
   * from queue
   * and clears, if any
   * @name handleStartDelay
   * @private
   * @memberof BuyBtnComponent
   */
  handleStartDelay() {
    if (this.usingKeyboard) {
      return;
    }
    try {
      if (!this.delayId) {
        this.delayId = delay(() => {
          this.handleHideCounter();
          this.handleClearDelay();
        }, this.delayDuration);
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * clears the delay timeout
   * from queue
   * @name handleClearDelay
   * @private
   * @memberof BuyBtnComponent
   */
  handleClearDelay() {
    try {
      if (this.delayId) {
        clearTimeout(this.delayId);
        this.delayId = null;
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * clears the delay timeout
   * from queue
   * @name handleRemoveBtnClearDelay
   * @private
   * @memberof BuyBtnComponent
   */
  private handleRemoveBtnClearDelay() {
    try {
      if (this.removeBtnDelayId) {
        clearTimeout(this.removeBtnDelayId);
        this.removeBtnDelayId = null;
      }
    } catch (error) {
      throw error;
    }
  }

  /**
   * get product quantity
   * @name getProductCount
   * @private
   * @returns {number} quantity
   * @memberof BuyBtnComponent
   */
  private getProductCount(): number {
    const { C000 } = this.data;
    return this.cartService.productCount(C000);
  }

  /**
   * Firebase analytics log
   * when product is being removed
   * @param {*} C000
   * @param {*} F004
   * @memberof BuyBtnComponent
   */
  private handleLogRemoveProduct(C000: string, F004: string) {
    this.analyticsService.productEvent(
      "removeFromCart",
      "product_removed",
      C000,
      F004,
      0,
      this.data
    );
    // condition for product decremented to zero
    this.analyticsService.productEvent(
      "removeFromCart",
      "product_quantity_decreased",
      C000,
      F004,
      0,
      this.data
    );
  }

  renderToolTipText(textString: string) {
    let text = this.translate.instant(textString);

    if (!this.noDeliveryTextHint.isTooltipDestroyed) {
      this.noDeliveryTextHint.destroyTooltip();
      this.noDeliveryTextHint.createTooltip();
    }
    this.noDeliveryTextHint.tooltipValue = text;
    this.noDeliveryTextHint.show();
  }

  switchToolTip(string: string) {
    this.renderToolTipText(string);
  }

  keyboardPress(event: KeyboardEvent, inputName) {
    const { nextElement, previousElement, hideCounter } =
      this.calcElementNavigation(inputName);
    if (event.key === "Tab") {
      this.isUsingKeyboard(true);
    }
    if (event.shiftKey && event.key === "Tab") {
      this.doBackwardsNavigation(previousElement, event, hideCounter);
    } else if (event.key === "Tab") {
      this.doForwardNavigation(nextElement, event);
    }
  }

  private doForwardNavigation(nextElement, event: KeyboardEvent) {
    if (nextElement) {
      event.preventDefault();
      nextElement.focus();
      this.showCounter();
    } else {
      this.handleHideCounter();
    }
  }

  private doBackwardsNavigation(
    previousElement,
    event: KeyboardEvent,
    hideCounter: boolean
  ) {
    if (previousElement) {
      event.preventDefault();
      previousElement.focus();
      if (!hideCounter) {
        this.showCounter();
      }
    } else {
      this.handleHideCounter();
    }
  }

  private calcElementNavigation(inputName) {
    let nextElement;
    let previousElement;
    let hideCounter = false;
    switch (inputName) {
      case "-":
        previousElement = this.getElementToFocusAfterBackwards();
        hideCounter = true;
        nextElement = this.quantityInput.nativeElement;
        break;
      case "quantity":
        previousElement = this.decreaseInput.nativeElement;
        nextElement = this.increaseInput.nativeElement;
        break;
      case "+":
        previousElement = this.quantityInput.nativeElement;
        nextElement = null;
        break;
    }
    return { nextElement, previousElement, hideCounter };
  }

  isUsingKeyboard(usingKeyboard) {
    const message = this.messageService.createCommand(
      Command.IS_USING_KEYBOARD,
      usingKeyboard
    );
    this.messageService.emitCommand(message);
  }

  getElementToFocusAfterBackwards(): HTMLElement {
    return document.getElementById("card_" + this.data.C000);
  }

  // reacts to [+] and [1] etc. button click and opens counter
  handleOpenCounterClick(e: Event) {
    if (e.type === "mousedown") {
      this.isUsingKeyboard(false);
    } else {
      this.isUsingKeyboard(true);
    }
    // @ts-ignore
    if (e.type === "keydown" && e.key === "Tab") {
      return;
    }
    this.handleShowCounter();
  }

  handleOpenCounterFutureDeliveryClick(e: Event) {
    if (e.type === "mousedown") {
      this.isUsingKeyboard(false);
    } else {
      this.isUsingKeyboard(true);
    }
    // @ts-ignore
    if (e.type === "keydown" && e.key === "Tab") {
      return;
    }

    const nextDeliveryDate = this.generalService
      .getFormattedDate(this.nextAvailableDateObj.date, "DD.MM.YYYY")
      .toUpperCase();

    let heading: string = this.translate.instant("PRODUCT.P007.value");
    let message: string = this.translate.instant("PRODUCT.P007A.value", {
      nextDeliveryDate: this.nextAvailableDateObj.date_text,
    });

    this.generalService.setModalState = {
      id: "confirmationModal",
      name: "confirmationModal",
      isOpen: true,
      data: {
        heading,
        message,
      },
      action: () => this.refetchProductListing(),
    };
  }

  private async refetchProductListing() {
    if (this.nextAvailableDateObj) {
      //Increment 1 Qty
      await this.handleShowCounter();

      const { date, default_start, default_end, start, end } =
        this.nextAvailableDateObj;
      const collicoObj = {
        date,
        timeFrom: default_start,
        timeTo: default_end,
        timeStart: start,
        timeEnd: end,
        zoneId: null,
        zoneName: null,
      };

      //Store to Local Storage
      await this.centralStorageService.setCheckoutInfoByCheckoutPage(
        "collico",
        collicoObj
      );

      // Todo: Have to remove setTimeout
      setTimeout(() => {
        //Store to Service
        this.collicoService.setCollicoDeliveryDateAndTime(
          this.nextAvailableDateObj
        );
      }, 1000);
    }
  }

  // this communicates with the parent element of all the productCards and receives messages
  messageHandler(incomingMessage: Command) {
    if (incomingMessage.isType(Command.CLOSE_OTHER_COUNTERS)) {
      this.handleMessageCloseOtherCounters(incomingMessage);
    } else if (incomingMessage.isType(Command.IS_USING_KEYBOARD)) {
      this.handleMessageIsUsingKeyboard(incomingMessage);
    }
  }

  // this is called when switching between MOUSE and KEYBOARD mode
  handleMessageIsUsingKeyboard(incomingMessage: Command) {
    if (this.usingKeyboard !== incomingMessage.commandData) {
      this.usingKeyboard = incomingMessage.commandData;
      if (this.usingKeyboard === false) {
        this.handleStartDelay();
      }
    }
  }

  // closes carts that are open
  handleMessageCloseOtherCounters(value: Command) {
    if (value.commandData !== this.data.C000) {
      this.handleHideCounter();
    }
  }

  private highlightNonStockProducts(nonStockProducts) {
    const productFound = nonStockProducts.find(Boolean);
    const { F001: productTitle } = this.data;
    if (productFound) {
      let { canOrder, forceSetQuantity } = productFound.F030[0];
      if (!canOrder) {
        // Limited Qty
        if (forceSetQuantity) {
          this.counter = forceSetQuantity;
          this.cartService.addToCart(this.data, forceSetQuantity);
          this.toastr.warning(
            this.translate.instant("CART.C064.value", {
              productTitle,
              forceSetQuantity,
            })
          );
        } else {
          // Out of stock
          this.handleRemoveProduct(this.data);
          this.handleHideCounter();
          this.toastr.error(
            this.translate.instant("CART.C063.value", { productTitle })
          );
        }
      }
    }
  }

  private handleRemoveBtnStartDelay() {
    try {
      this.isRemoveBtnVisible = true;
      if (!this.removeBtnDelayId) {
        this.removeBtnDelayId = delay(() => {
          this.isRemoveBtnVisible = false;
          //this.cartService.setTempProductsTotal = null;
          this.handleRemoveBtnClearDelay();
        }, this.delayDuration);
      }
    } catch (error) {
      throw error;
    }
  }
}
