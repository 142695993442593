export const environment = {
  production: true,
  svAPIKey: "KHL321",
  baseUrl: "https://api-php.storeverse.io/api/v1",
  googleAppId:
    "734567008925-nvbbrlecot0d1dip8pb7f16anu34i2mp.apps.googleusercontent.com",
  facebookAppId: "1021525404867816",
  firebase:  {
    apiKey: "AIzaSyCttOotFI9WYBtTYLfVJvlhvI4O_JsH_q8",
    authDomain: "storeversekh24-production.firebaseapp.com",
    projectId: "storeversekh24-production",
    storageBucket: "storeversekh24-production.appspot.com",
    messagingSenderId: "10440934368",
    appId: "1:10440934368:web:3251b419674d428c86e185",
    measurementId: "G-8VS345Q9P7"
  },
};
